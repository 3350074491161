// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import $ from 'jquery'
require("@hotwired/turbo-rails")

Rails.start()

//import "controllers"

import 'web'

window.jQuery = $;
window.$ = $;


import "controllers"

$(window).on("turbo:load ready turbo:render", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()

  $(document).on('change', 'input[type="checkbox"].onlyOne', function(){
    $(this).siblings('input[type="checkbox"].onlyOne').prop('checked', false);
  });
})
