import { Controller } from "stimulus"

import $ from 'jquery'
import 'bootstrap-datepicker'
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.de'
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

export default class extends Controller {
  static values = {
    params: Object
  }

  initialize () {
    this.defaults = {
      keyboardNavigation: false,
      forceParse: false,
      autoclose: true,
      maxViewMode: 3,
      language: 'de',
      todayHighlight: true
    }
  }

  connect() {
    let config = Object.assign(this.paramsValue, this.defaults)

    $(this.element).datepicker(config)
  }
}
